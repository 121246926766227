$(document).on("turbolinks:load", function() {
  $(".strategy-trigger").on("click", function(event) {
    $(event.target.dataset["target"]).toggle();
    if ($(event.target.dataset["target"]).is(":visible")) {
      $(".strategies-container").addClass("noselect");
    } else {
      $(".strategies-container").removeClass("noselect");
    }
  });

  $(".hoverable").hover(function(event) {
    $(event.target).addClass("hover");
  });

  $(".hoverable").mouseout(function(event) {
    $(this).removeClass("hover");
  });

  $(".hoverable").on("click", function(event) {
    $(this).removeClass("hover");
  });
});
